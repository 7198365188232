import { createAction, createReducer, createSelector } from '@reduxjs/toolkit';
import createEnhancedThunk from '../../enhancedAsyncThunkCreator';
import { RootState } from '../../rootReducer';
import {
  EditBulkUserResponse,
  EditBulkUserType,
  EditStateUserType,
  EditUserResponse,
  EditUserType,
  GetUserResponse, GetUsersResponse, GetUsersType,
  GetUserType, PostUserResponse, PostUserType,
  UserState, UserStateType,
} from './users.types';
import { withoutKey } from '../../../utilities';
import { tablePaginationParams } from '../../../staticData';
import { PaginationType } from '../../apiTypes/shared/pagintaion';
import { dispatchErrorMessage, errorMessageAction, successMessageAction } from '../ui/ui';

const initialErrorsState = {
  errors: null,
};

const initialState: UserState = {
  result: [],
  currentUser: null,
  user: {
    id: 0,
    email: '',
    first_name: '',
    last_name: '',
    title: '',
    birth_date: '',
    phone_number: '',
    group_lot_entry_fee: 0,
    terms_agreement: false,
    age: 0,
    full_name: '',
    last_login: '',
    is_superuser: false,
    additional_phone_number: '',
    loyalty_level: '',
    is_active: false,
    is_staff: false,
    is_completed: false,
    registration_date: '',
    registration_fee_status: false,
    private_note: '',
    promotional_email: false,
    is_audio_cues: false,
  },
  isUserLoading: false,
  tokens: null,
  isLoading: false,
  ...tablePaginationParams,
  ...initialErrorsState,
};
const createAsyncThunk = createEnhancedThunk('users');

export const getUsersAction = createAsyncThunk<GetUsersType, PaginationType & any>('getUsers',
  async ({ page, perPage, params }, thunkAPI) => {
    try {
      const response = await thunkAPI.extra.get<PaginationType, GetUsersResponse>('/api/users/', {
        params: {
          page: page + 1,
          page_size: perPage,
          ...params,
        },
      });
      return response.data;
    } catch (err) {
      thunkAPI.dispatch(dispatchErrorMessage(err));
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ['Server error'] },
      });
    }
  });

export const getUsersTokensAction = createAsyncThunk<any, any>('getUsersTokensAction',
  async ({ id }, thunkAPI) => {
    try {
      const response = await thunkAPI.extra.get<any, any>(`/api/users/${id}/tokens/`);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ['Server error'] },
      });
    }
  });

export const getUserAction = createAsyncThunk<GetUserType, { id: number }>('getUser',
  async ({ id }, thunkAPI) => {
    try {
      const response = await thunkAPI.extra.get<{ id: number }, GetUserResponse>(`/api/users/${id}/`);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ['Server error'] },
      });
    }
  });

export const getCurrentUserAction = createAsyncThunk<GetUserType, void>('currentUser',
  async (_, thunkAPI) => {
    try {
      const response = await thunkAPI.extra.get<void, GetUserResponse>('/api/users/current/');
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ['Server error'] },
      });
    }
  });

export const postUserAction = createAsyncThunk<PostUserType, UserStateType>('postUserAction',
  async (data, thunkAPI) => {
    try {
      const userData = data.additional_phone_number ? data : withoutKey(data, ['additional_phone_number']);
      const response = await thunkAPI.extra.post<UserStateType, PostUserResponse>('/api/users/', userData);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ['Server error'] },
      });
    }
  });

export const editUserAction = createAsyncThunk<EditUserType, { id: number, data: EditStateUserType }>('editAuctions',
  async ({
    id,
    data,
  }, thunkAPI) => {
    try {
      const response = await thunkAPI.extra.patch<{ id: number, data: EditStateUserType }, EditUserResponse>(`/api/users/${id}/`, {
        ...data,
      });

      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ['Server error'] },
      });
    }
  });

export const bulkEditUserAction = createAsyncThunk<EditBulkUserType, { data: EditStateUserType[] }>('bulkEditUserAction',
  async ({
    data,
  }, thunkAPI) => {
    try {
      const response = await thunkAPI.extra.patch<{ data: EditStateUserType[] }, EditBulkUserResponse>('/api/users/', data);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ['Server error'] },
      });
    }
  });

export const putUserPasswordManual = createAsyncThunk<void, { new_password: string, repeat_password: string, id: number }>('putUserPasswordManual',
  async ({ id, new_password, repeat_password }, thunkAPI) => {
    try {
      await thunkAPI.extra.put<void>(`/api/auth/admin/user_password_reset/${id}/`, {
        new_password_repeat: repeat_password,
        new_password,
      });
      thunkAPI.dispatch(successMessageAction("Password has been changed successfully"));
      return undefined;
    } catch (err) {
      thunkAPI.dispatch(errorMessageAction("Password changing failure"));
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ['Server error'] },
      });
    }
  });

export const postUserAddressRequest = createAsyncThunk<void, number[]>('postUserAddressRequest',
  async (userdIds, thunkAPI) => {
    try {
      await thunkAPI.extra.post<void>(`/api/users/send_shipping_reminders/`, {
        objects: userdIds,
      });
      thunkAPI.dispatch(successMessageAction("Request has been sent"));
      return undefined;
    } catch (err) {
      thunkAPI.dispatch(errorMessageAction("Request sending failure"));
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ['Server error'] },
      });
    }
  });

export const resetUsersForm = createAction('resetUsersForm');
export const resetCurrentUser = createAction('reseCurrenttUsers');

export default createReducer(initialState, (builder) => {
  builder.addCase(getUsersTokensAction.fulfilled, (state, { payload }) => ({
    ...state,
    ...initialErrorsState,
    tokens: payload.data.results,
  }));
  builder.addCase(getUsersTokensAction.pending, (state) => ({
    ...state,
  }));
  builder.addCase(getUsersTokensAction.rejected, (state, action) => ({
    ...state,
    ...initialErrorsState,
    // @ts-ignore
    errors: action?.payload?.messages,
  }));
  builder.addCase(getUsersAction.fulfilled, (state, { payload, meta }) => ({
    ...state,
    ...initialErrorsState,
    result: payload.data.results,
    count: payload.data.count,
    page: meta.arg.page,
    perPage: meta.arg.perPage,
    isLoading: false,
  }));
  builder.addCase(getUsersAction.pending, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(getUsersAction.rejected, (state, action) => ({
    ...state,
    ...initialErrorsState,
    // @ts-ignore
    errors: action?.payload?.messages,
    isLoading: false,
  }));

  builder.addCase(getUserAction.fulfilled, (state, { payload }) => ({
    ...state,
    ...initialErrorsState,
    user: payload.data,
    isUserLoading: false,
  }));
  builder.addCase(getUserAction.pending, (state) => ({
    ...state,
    isUserLoading: true,
  }));
  builder.addCase(getUserAction.rejected, (state, action) => ({
    ...state,
    ...initialErrorsState,
    // @ts-ignore
    errors: action?.payload?.messages,
    isUserLoading: false,
  }));
  builder.addCase(getCurrentUserAction.fulfilled, (state, { payload }) => ({
    ...state,
    ...initialErrorsState,
    currentUser: payload.data,
    isUserLoading: false,
  }));
  builder.addCase(getCurrentUserAction.pending, (state) => ({
    ...state,
    isUserLoading: true,
  }));
  builder.addCase(getCurrentUserAction.rejected, (state, action) => ({
    ...state,
    ...initialErrorsState,
    // @ts-ignore
    errors: action?.payload?.messages,
    isUserLoading: false,
  }));

  builder.addCase(postUserAction.fulfilled, (state, action) => {
    const users = state.result.map((user) => {
      if (user.id === action.payload.data.id) {
        return action.payload.data;
      }
      return user;
    });

    return ({
      ...state,
      ...initialErrorsState,
      user: action.payload.data,
      result: users,
      isLoading: false,
    });
  });
  builder.addCase(postUserAction.pending, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(postUserAction.rejected, (state, action) => ({
    ...state,
    ...initialErrorsState,
    // @ts-ignore
    errors: action?.payload?.messages,
    isLoading: false,
  }));
  builder.addCase(editUserAction.fulfilled, (state, action) => {
    const users = state.result.map((user) => {
      if (user.id === action.payload.data.id) {
        return action.payload.data;
      }
      return user;
    });
    const currentUser = state?.currentUser?.id === action.payload.data.id;
    return ({
      ...state,
      ...initialErrorsState,
      user: action.payload.data,
      result: users,
      currentUser: currentUser ? action.payload.data : state.currentUser,
      isLoading: false,
    });
  });
  builder.addCase(editUserAction.pending, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(editUserAction.rejected, (state, action) => ({
    ...state,
    ...initialErrorsState,
    // @ts-ignore
    errors: action?.payload?.messages,
    isLoading: false,
  }));
  builder.addCase(bulkEditUserAction.fulfilled, (state, action) => {
    const users = state.result
      .map((obj) => action.payload.data
        .find((o) => o.id === obj.id) || obj);
    return ({
      ...state,
      ...initialErrorsState,
      result: users,
      isLoading: false,
    });
  });
  builder.addCase(bulkEditUserAction.pending, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(bulkEditUserAction.rejected, (state, action) => ({
    ...state,
    ...initialErrorsState,
    // @ts-ignore
    errors: action?.payload?.messages,
    isLoading: false,
  }));
  builder.addCase(resetUsersForm.type, (state) => ({
    ...state,
    ...initialErrorsState,
    user: initialState.user,
  }));
  builder.addCase(resetCurrentUser.type, (state) => ({
    ...state,
    ...initialErrorsState,
    currentUser: initialState.currentUser,
  }));
});

export const selectLoadingStatus = createSelector(
  (state: RootState) => state.users.isLoading,
  (id) => id,
);

export const selectUsers = createSelector(
  (state: RootState) => state.users.result,
  (result) => result,
);

export const selectUsersCount = createSelector(
  (state: RootState) => state.users.count,
  (count) => count,
);

export const selectUsersPage = createSelector(
  (state: RootState) => state.users.page,
  (count) => count,
);

export const selectUsersPerPage = createSelector(
  (state: RootState) => state.users.perPage,
  (count) => count,
);

export const selectUser = createSelector(
  (state: RootState) => state.users.user,
  (user) => user,
);

export const selectCurrentUser = createSelector(
  (state: RootState) => state.users.currentUser,
  (user) => user,
);

export const selectIsUserLoading = createSelector(
  (state: RootState) => state.users.isUserLoading,
  (isUserLoading) => isUserLoading,
);

export const selectErrors = createSelector(
  (state: RootState) => state.users.errors,
  (errors) => errors,
);
